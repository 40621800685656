.cart-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.order-summary, .order-form {
  width: 46%;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.order-form {
  place-items: center;
}

.order-summary {
  background-color: rgb(3, 3, 3, 0.2);
}

.order-summary h2, .order-form h2 {
  margin-bottom: 10px;
  color: #333;
  text-align: center; /* Center the h2 headings */
}

.cart-items-list {
  list-style-type: none;
  padding: 0;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cart-item-info {
  display: flex;
  align-items: center;
}

.cart-item-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 20px;
  border-radius: 4px;
}

.cart-item-info div {
  display: flex;
  flex-direction: column;
}

.remove-button {
  background-color: #030303;
  color: #fff;
  width: 15%;
  border: 1px solid #000;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.remove-button:hover {
  background-color: #fff;
  color: #000;
}

.cart-summary {
  text-align: right;
  margin-top: 20px;
}

.order-form {
  background-color: #fff;
}

.personal-details {
  margin-bottom: 20px;
}

.personal-details h3 {
  margin-bottom: 10px;
  color: #131212;
}


.form-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.form-group input {
  width: 50%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 4px;
}

.payment-providers {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.payment-providers img {
  width: 100px;
  height: auto;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
}

.cancel-button, .complete-purchase-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 2px;
}

.cancel-button {
  background-color: #fff;
  color: #000;
  border: #000 solid 2px;
}

.cancel-button:hover {
  background-color: #0c0c0c;
  color: #fff;
}

.complete-purchase-button {
  background-color: #0c0c0c;
  color: #fff;
}

.complete-purchase-button:hover {
  background-color: #fff;
  color: #000;
}

/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .cart-container {
    flex-direction: column;
    align-items: center;
  }

  .order-summary, .order-form {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    text-align: center; /* Center text for mobile view */
  }

  .cart-item {
    flex-direction: column;
    align-items: center;
  }

  .cart-item-info {
    flex-direction: column;
    align-items: center;
  }

  .cart-item-image {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
  }

  .remove-button {
    width: fit-content;
  }

  .form-group {
    flex-direction: column;
    align-items: center;
  }

  .form-group input {
    width: 100%;
    margin-bottom: 10px;
  }

  .payment-providers {
    flex-direction: column;
    align-items: center;
  }

  .payment-providers img {
    width: 80px;
    margin-bottom: 10px;
  }

  .form-buttons {
    flex-direction: column;
  }

  .form-buttons button {
    width: 100%;
    margin-bottom: 10px;
  }
}
