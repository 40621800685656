.home {
  text-align: center;
  padding: 0;
}

.hero {
  position: relative;
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;
}

.video-container {  /* Target the added class for responsiveness */
  position: relative;
  padding-bottom: 56.25%; /* Maintain aspect ratio (adjust if needed) */
  height: 0vh;
  overflow: hidden;
}

.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the entire area */
  pointer-events: none; /* Prevents interactions with the video */
}

.hero-content {
  position: relative;
  z-index: 1; /* Places content above the video */
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10%; /* Centers content vertically */
  padding: 20px;
  margin-top: 15%;
}

.hero-content h1 {
  color: #fff;
  font-family: 'Arial Black', sans-serif;
  font-size: 24px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  animation: textGlow 1.5s ease-in-out infinite alternate;
}

@keyframes textGlow {
  from {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px #fff, 0 0 20px rgb(12, 12, 19), 0 0 30px rgb(15, 15, 15), 0 0 40px rgb(20, 20, 20), 0 0 55px rgb(20, 20, 20), 0 0 75px rgb(17, 17, 17);
  }
  to {
    text-shadow: 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff, 0 0 50px rgb(10, 10, 10), 0 0 60px rgb(15, 15, 15), 0 0 70px rgb(8, 8, 8), 0 0 80px rgb(10, 10, 10), 0 0 100px rgb(7, 7, 7);
  }
}

.hero-content button {
  font-size: 2.0rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Adds readability */
  margin-top: 50%;
  bottom: 0;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  padding: 1rem;
  border-radius: 8px;
}

.about-container{
  font-size: 30px;
  border: #050505 2px solid;
}

.categories {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.category {
  width: 30%;
  background: #f4f4f4;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0; /* Add some margin for spacing */
  text-align: center;
}

.category img {
  width: 100%;  /* Allow the image to scale with its container */
  height: auto;  /* Maintain the aspect ratio */
  max-height: 200px; /* Prevent images from getting too tall */
  object-fit: contain; /* Ensures the image scales inside without distortion */
  border-radius: 8px;
}


.category h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
}

.category p {
  font-size: 1rem;
  color: #555;
}

.view-more {
  margin: 1rem;
}

.view-more-button {
  background-color: #050505;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
  transition: background-color 0.3s;
}

.view-more-button:hover {
  background-color: #fcfbfb;
  color: #050505;
  border: #050505 solid 2px;
}


.logos {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  background-color: white;
  border: #050505 solid 2px;
  padding: 20px 0;
  position: relative;
}

.logos .logos-slide {
  display: flex;
  align-items: center;
  animation: scroll 15s linear infinite; /* Adjust speed as needed */
}

.logoi {
  flex: 0 0 auto;
  margin: 0 40px; /* Increased spacing to balance sizes */
}

.logoi img {
  width: 150px; /* Increased size for better visibility */
  height: 100px; /* Ensure uniform height */
  object-fit: contain; /* Keep the aspect ratio while fitting in */
  display: block;
}


@keyframes scroll {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-60%);
  }
}


/* Mobile View */
@media only screen and (max-width: 768px) {
  .video-container {  /* Target the added class for responsiveness */
    position: relative;
    padding-bottom: 56.25%; /* Maintain aspect ratio (adjust if needed) */
    height: 0vh;
    overflow: hidden;
  }
  .category {
    width: 90%;
    margin: 1rem auto;
  }

  .hero {
    height: 40vh; /* Full viewport height */
    overflow: hidden;
    background: #000;
  }

  .hero-content button {
    font-size: 1.0rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Adds readability */
    margin-top: 15%;
    bottom: 0;
  }
  
  .hero-content {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    padding: 1rem;
    border-radius: 8px;
  }
  
  .hero-content h1 {
    color: #fff;
    font-family: 'Arial Black', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    letter-spacing: 2px;
    animation: textGlow 1.5s ease-in-out infinite alternate;
  }
}