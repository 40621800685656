.container {
    padding: 20px;
    text-align: center;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 items per row */
    gap: 20px;
    justify-items: center;
  }
  
  /* Responsive adjustments */
  @media (max-width: 900px) {
    .grid {
      grid-template-columns: repeat(2, 1fr); /* 2 per row on smaller screens */
    }
  }
  
  @media (max-width: 600px) {
    .grid {
      grid-template-columns: repeat(1, 1fr); /* 1 per row on mobile */
    }
  }
  
  
  .category-card, .product-card {
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    transition: transform 0.2s;
    max-width: 400px;
  }
  
  .category-card:hover, .product-card:hover {
    transform: scale(1.05);
  }
  
  .category-image, .product-image {
    width: 100%;
    max-height: 400px;
    height: auto;
    border-radius: 8px;
  }
  
  .category-name {
    font-size: 18px;
    margin-top: 10px;
  }
  
  .back-button {
    background: black;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  