.about-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.about-container h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.about-container p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

.map-container {
    margin-top: 40px;
    border: 2px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
}
