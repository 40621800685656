/* General container styling */
.container {
  padding: 15px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

/* Back button */
.back-button {
  background: black;
  color: white;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  margin-bottom: 15px;
  border-radius: 5px;
}

.back-button:hover {
  background: #444;
}

/* Product grid */
.product-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.product-card {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
}

.product-card img {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.product-card h3 {
  font-size: 16px;
  margin: 10px 0;
}

.product-card p {
  font-size: 14px;
}

.product-card button {
  background: black;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 5px;
}

.product-card button:hover {
  background: #333;
}

/* Responsive styling */
@media (max-width: 1024px) {
  .product-grid {
      grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 425px) {
  .product-grid {
      grid-template-columns: repeat(1, 1fr);
  }

  .back-button {
      width: 100%;
      text-align: center;
  }
}

@media (max-width: 480px) {
  .container {
      padding: 10px;
  }

  .product-card {
      padding: 8px;
  }

  .product-card img {
      height: 180px;
  }

  .product-card h3 {
      font-size: 14px;
  }

  .product-card p {
      font-size: 12px;
  }

  .product-card button {
      padding: 8px;
  }
}
