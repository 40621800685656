.contact-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.contact-content {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

.contact-form, .contact-details {
    width: 46%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px; /* More spacing between inputs */
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 12px; /* Increased padding */
    border: 1px solid #999;
    border-radius: 4px;
    box-sizing: border-box; /* Ensures padding doesn’t shrink width */
    font-size: 16px;
}

.form-group input {
    height: 45px; /* Bigger input field */
}

.form-group textarea {
    min-height: 120px; /* Increased textarea height */
}

.form-group input:focus,
.form-group textarea:focus {
    border-color: #080808;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

button {
    background-color: #080808;
    color: #fff;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
    font-size: 16px;
}

button:hover {
    background-color: #fcfbfb;
    color: #050505;
    border: 2px solid #050505;
}

.contact-details h3 {
    margin-bottom: 15px;
}

.contact-details p {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.contact-details p svg {
    margin-right: 10px;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .contact-content {
        flex-direction: column;
        align-items: stretch;
    }

    .contact-form, .contact-details {
        width: 100%;
        margin: 0 0 20px 0; /* Margin bottom for spacing between sections */
    }
}

@media (max-width: 425px) {
    .contact-form, .contact-details {
        padding: 15px;
    }

    .form-group input,
    .form-group textarea {
        padding: 10px;
        font-size: 14px;
    }

    button {
        padding: 10px 18px;
        font-size: 14px;
    }
}
