/* src/styles/ProductDetails.css */
.product-details-container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.product-details-carousel {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
}

.product-details-carousel img {
  width: 100%;
  min-height: 550px;
  max-height: 650px;
  object-fit: contain;
  transition: opacity 0.5s ease-in-out;
}

.product-details-info {
  flex: 1;
}

.product-details-price {
  color: #4a5568;
}

.product-details-description {
  line-height: 1.6;
}

.add-to-cart-button {
  text-decoration: none;
}

.back-link {
  text-decoration: dotted;
}

@media (min-width: 425px) {
  .product-details-container {
    flex-direction: row;
    gap: 40px;
  }

  .product-details-carousel {
    width: 50%;
  }

  .product-details-info {
    width: 100%;
  }
}



.product-details-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
  max-width: 1200px;
}


.product-details-info {
  flex: 1 1 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.product-details-info h2 {
  margin-bottom: 1rem;
  font-size: 2rem;
  color: #333;
}

.product-details-price {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
}

.product-details-category {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1rem;
}

.product-details-description {
  flex-grow: 1;
  margin-bottom: 2rem;
  color: #555;
  line-height: 1.5;
}

.quantity-control {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.quantity-control button {
  background-color: #080808;
  color: #fff;
  border: none;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.quantity-control button:hover {
  background-color: #fcfbfb;
  color: #050505;
  border: #050505 solid 2px;
}

.quantity-control span {
  font-size: 1.5rem;
  color: #333;
}

.button, .add-to-cart-button {
  background-color: #080808;
  margin: 10px;
  color: #fff;
  padding: 12px;
  font-size: 1.2rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-to-cart-button:hover {
  background-color: #fcfbfb;
  color: #050505;
  border: #050505 solid 2px;
}

.back-link h2 {
  font-size: 1.5rem;
}

@media (max-width: 425px) {
  h2, h3 {
    font-size: 15px;
  }
  p {
    font-size: 13px;
  }
  .product-details-container {
    flex-direction: column;
    align-items: center;
  }

  .product-details-carousel {
    width: 100%;
  }

 

  .product-details-description {
    text-align: justify;
  }

}
